import gsap from 'gsap';
import { lockScroll, setIntersectionObserver } from '../helpers';


class Navigation {

    constructor() {
        self.Decimal.scroll = self.Decimal.scroll || {};
        self.Decimal.scroll.direction = self.Decimal.scroll.direction || {};
        self.Decimal.scroll.currentProxy = self.Decimal.scroll.currentProxy || {};

        this.container = document.querySelector('[data-navigation]');
        this.active = null;
        this.bounds = null;
        this.prevBounds = null;
        this.delay = 300;
        this.opened = false;
        this.timeout = null;
        this.isOpening = false;
        this.hideOnScroll = true;
        this.isAnimating = false;
        this.isHide = false;
        this.threshold = 200;
        this.currentFocus = null;
        this.isMobile = window.Decimal.isMobile
        this.els = {
            dropdowns: this.getDropdownsEls(),
            triggers: this.container.querySelectorAll(".dropdown--trigger"),
        };

        this.init();
        this.bind();
        /** Timeline */
        this.setTimelines();

        if (this.isMobile) this.initMobileNavigation()

        /** Observer for Proxy */
        /* 
        const handler = {
        set: (target, property, value) => {

            target[property] = value;

            this.handleScrollDirection(value);

            return true;
        }
    };

    const scrollHandler = {
        set: (target, property, value) => {

            target[property] = value;

            this.handleScrollDirection(self.Decimal.scroll.direction.dir, value);

            return true;
        }
    };
    /** Wrap this object in a Proxy */
        /*
            self.Decimal.scroll.direction = new Proxy(self.Decimal.scroll.direction, handler);
            self.Decimal.scroll.currentProxy = new Proxy(self.Decimal.scroll.currentProxy, scrollHandler);
            */

    }

    init() {
        self.Decimal.observer = self.Decimal.observer || setIntersectionObserver()
    }

    bind() {

        if (this.isMobile) {
            /** Set mobile styles */
            if (this.isMobile) this.initMobileNavigation();
            // this.els.closes.forEach((nav) => nav.addEventListener("touchstart", this.closeMobileDrawer.bind(this)));
            // this.els.closes.forEach((nav) => nav.addEventListener("touchstart", this.closeMobileSearch.bind(this)));

        } else {
            this.els.triggers.forEach((trigger, i) => {
                trigger.addEventListener('mouseenter', this.menuOpen.bind(this, i))
                trigger.addEventListener('focusin', this.menuOpen.bind(this, i))
                trigger.addEventListener('mouseleave', this.menuClose.bind(this, i))
                trigger.addEventListener('focusout', this.menuClose.bind(this, i))
            })
        }
        this.scrollHandler();

    }

    scrollHandler() {
        const checkScrollPosition = () => {
            const scrollY = window.scrollY;
            const desktopHeader = document.querySelector('#shopify-section-layout--header');
            const mobileHeader = document.querySelector('#shopify-section-layout--mobile-navigation');
            setStickyClasses(desktopHeader, scrollY);
            setStickyClasses(mobileHeader, scrollY);

            function setStickyClasses(header, currentScrollY){
                header.classList.add('is-top');
                return
                if (currentScrollY > 150) {
                    header.classList.remove('scroll-down');
                    if (currentScrollY > window.lastScrollY) {
                        header.classList.add('scroll-down');
                    }
                    header.classList.add('is-fixed-scroll');
                    header.classList.remove('is-top');
                } else {
                    header.classList.remove('scroll-down');
                    header.classList.remove('is-fixed-scroll');
                    header.classList.add('is-top');
                }
            }
            
            window.lastScrollY = scrollY;
        }

        window.addEventListener('scroll', () => {
            checkScrollPosition();
        });
        checkScrollPosition();
    };

    getDropdownsEls() {
        return [...this.container.querySelectorAll(".dropdown")].map(dropdown => (
            {
                el: dropdown,
                children: dropdown.querySelectorAll('li')
            }
        ))
    }

    menuOpen(i, event) {

        lockScroll(true);

        this.dropdownAnimations[i].timeScale(1)

        this.dropdownAnimations[i].play();

    }


    menuClose(i, event) {

        lockScroll(false);

        this.dropdownAnimations[i].timeScale(3)

        this.dropdownAnimations[i].reverse()

    }

    initMobileNavigation() {

        this.container = document.querySelector('.mobile-navigation');
        this.drawer = this.container.querySelector('.nav-drawer');
        this.openBtn = this.container.querySelector('.btn-open');
        this.closeBtn = this.container.querySelector('.btn-close');
        this.links = [...this.drawer.querySelectorAll('.menu-item')];
        this.els.mobileTriggers = [...this.container.querySelectorAll('.dropdown--trigger')];
        this.els.submenus = [...this.drawer.querySelectorAll('.dropdown')];

        this.dropdownIsOpen = false;

        this.openBtn.addEventListener('click', this.openMobileDrawer.bind(this));
        this.closeBtn.addEventListener('click', this.closeMobileDrawer.bind(this));

        gsap.set(this.drawer, { autoAlpha: 0 })

        this.els.submenus.forEach(submenu => gsap.set(submenu, { autoAlpha: 0, height: 0 }))

        this.setMobileTimeline();
        this.setSubNav();
    }

    setSubNav() {

        this.container.addEventListener('click', e => {
            // close all submenus if outside of the triggers
            if (!e.target.closest('.dropdown--trigger')) {
                this.els.submenus.forEach(submenu => gsap.to(submenu, { height: 0, autoAlpha: 0, duration: 0.5 }))

            }
        })

        // close menu when we click in a child link
        this.els.submenus.forEach(submenu => {
            submenu.querySelectorAll('a').forEach(link => {
                link.addEventListener('click', e => {
                    this.closeMobileDrawer();
                })
            })
        })

        this.els.mobileTriggers.forEach((trigger, i) => {
            trigger.addEventListener('click', this.toggleSubNav.bind(this, i))
        })
    }

    toggleSubNav(i, e) {

        if (!e.target.closest('a') || !e.target.closest('.dropdown')) {

            e.preventDefault()
        }

        const targetMenu = this.els.submenus[i]


        if (this.dropdownIsOpen) {
            const otherMenu = this.els.submenus.filter((el, idx) => idx !== i)[0]
            gsap.to(otherMenu, { height: 0, autoAlpha: 0, duration: 0.3, ease: "sine.inOut" })

        }

        gsap.to(targetMenu, {
            height: 'auto',
            autoAlpha: 1,
            duration: 0.25,
            delay: this.dropdownIsOpen && 0.1,
            ease: "sine.inOut"
        })

        this.dropdownIsOpen = true;

    }

    openMobileDrawer() {

        self.Decimal.helpers.lockScroll(true);

        this.mobileTl.play()

        this.isOpen = true

        this.container.classList.add('is-open')
    }

    closeMobileDrawer() {

        self.Decimal.helpers.lockScroll(false)

        this.mobileTl.timeScale(2).reverse()

        this.isOpen = false

        setTimeout(() => {
            this.container.classList.remove('is-open')
        }, 2000)


    }


    toggleMobileDrawer(event) {


        //* Close */
        if (this.isOpen) {

            this.closeMobileDrawer();
            //* Open */
        } else {
            this.openMobileDrawer();

        }

    }


    setTimelines() {

        this.dropdownAnimations = this.els.dropdowns.map(() => {
            return gsap.timeline({
                paused: true
            });
        })

        this.dropdownAnimations.forEach((tl, i) => {


            tl.set(this.els.dropdowns[i].el, {
                autoAlpha: 1
            })

            tl.fromTo(this.els.dropdowns[i].children, {
                autoAlpha: 0,
            },
                {
                    autoAlpha: 1,
                    duration: 1.2,
                    // duration: 0.7,
                    stagger: 0.025,
                    ease: "power2.out"
                });

            // tl.set(this.els.dropdowns[i].children, {
            //     autoAlpha: 1,
            //     y: 0
            // })
        })

        this.scrollTimeline = gsap.timeline({ paused: true });


        this.scrollTimeline.fromTo(this.container, {
            y: 0
        }, {
            y: "-130%",
            duration: 0.3,
            ease: "power2.inOut",
        });



        this.scrollTimeline.eventCallback("onComplete", () => {
            this.currentDirection = "down";
            this.isAnimating = false;
        });

        this.scrollTimeline.eventCallback("onReverseComplete", () => {
            this.currentDirection = "up";
            this.isAnimating = false;
        });


    }

    setMobileTimeline() {

        this.mobileTl = gsap.timeline({
            paused: true,
        })
        this.mobileTl.set(this.container, { mixBlendMode: 'normal' })

        this.mobileTl.to(this.drawer, { autoAlpha: 1, duration: .2 })
        this.mobileTl.fromTo(this.links, {
            autoAlpha: 0,

        }, {
            duration: 1,
            stagger: 0.15,
            autoAlpha: 1,

        }, 0)

    }

    setPanelHeight(panel, pencilBar = null) {
        let height;
        if (pencilBar) {
            height = self.innerHeight - this.el.offsetHeight - panel.menu.closest('nav').offsetHeight - pencilBar?.offsetHeight;
        } else {
            height = self.innerHeight - this.el.offsetHeight - panel.menu.closest('nav').offsetHeight;
        }
        gsap.to(panel.menu, { height: `${height}px`, duration: 0.2 })
    }

    forceFocusOnPanel(panel) {

        if (this.activePanel == panel) return;

        gsap.to(this.activePanel.menu, { autoAlpha: 0 });
        this.activePanel.classList.remove('active');

        this.activePanel = panel;
        this.activePanel.classList.add('active')
        gsap.to(this.activePanel.menu, { autoAlpha: 1 });
        this.activePanel.menu.scrollTop = 0;


        if (this.activePanel.tabDirection == 'forward') this.activePanel.tabDirection = 'backward'
        if (this.activePanel.tabDirection == 'backward') this.activePanel.tabDirection = 'forward'

    }

    keyDownHandler(e, generalContext = false) {

        if (generalContext) {
            /** Keydown on nav element */
            switch (e.keyCode) {

                /** Escape*/
                case (27):
                    this.generalContext && this.isOpen && this.toggleMenu();
                    break;

            }
        } else {
            /** Keydown on trigger btn */
            switch (e.keyCode) {

                /** Enter & Space */
                case 32:
                case 13:
                    this.toggleMobileDrawer();
                    break;

            }
        }



    }

    /** Handle scroll up/down */
    handleScrollDirection(direction = "idle", scroll = self.Decimal.scroll.currentProxy) {


        if (this.isAnimating === true || this.currentDirection === direction) return;
        if (this.opened) this.menuClose();

        if (direction == "down" && scroll > this.threshold) {

            this.isAnimating = true;
            this.scrollTimeline.play(0);
        }

        if (direction == "up") {

            this.isAnimating = true;
            this.scrollTimeline.reverse(0);
        }
    }

}


export default Navigation
